<template>
  <div>
    <el-card>
      <Combination :listName="title"></Combination>
      <div class="list-contetnt-header">
        <div class="list-content-select">
          <div class="row-span">
            <div class="list-title">员工姓名:</div>
            <el-input
              placeholder="请填写员工姓名"
              suffix-icon="el-icon-user"
              v-model="employees.name"
            >
            </el-input>
          </div>
          <div class="row-span">
            <div class="list-title">员工账号:</div>
            <el-input
              placeholder="请填写员工账号"
              suffix-icon="el-icon-collection-tag"
              v-model="employees.stuffCode"
            >
            </el-input>
          </div>

          <div class="row-span">
            <div class="list-title">员工昵称:</div>
            <el-input
              placeholder="请填写账号名称"
              suffix-icon="el-icon-user"
              v-model="employees.stuffName"
            >
            </el-input>
          </div>
          <div class="row-span">
            <div class="list-title">工会名称:</div>
            <el-input
              placeholder="工会名称"
              suffix-icon="el-icon-user"
              v-model="employees.presidentName"
            >
            </el-input>
          </div>
          <div class="row-span">
            <div class="list-title">组名称:</div>
            <el-input
              placeholder="组名称"
              suffix-icon="el-icon-user"
              v-model="employees.groupName"
            >
            </el-input>
          </div>

          <div class="row-span">
            <div class="list-title">性别:</div>
            <el-input
              placeholder="请填写性别"
              suffix-icon="el-icon-user"
              v-model="employees.gender"
            >
            </el-input>
          </div>

          <div class="row-span">
            <div class="list-title">备注:</div>
            <el-input
              placeholder="请填写备注"
              suffix-icon="el-icon-user"
              v-model="employees.remark"
            >
            </el-input>
          </div>

          <!-- <el-row :gutter="20" style="margin-top: 20px;">
            <el-col :span="6">
              <div class="row-span">
                <div class="list-title">备注:</div>
                <el-input
                  placeholder="请填写备注"
                  suffix-icon="el-icon-user"
                  v-model="employees.remark"
                >
                </el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="row-span">
                <div class="list-title">工会名称:</div>
                <el-input
                  placeholder="工会名称"
                  suffix-icon="el-icon-user"
                  v-model="employees.presidentName"
                >
                </el-input>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="row-span">
                <div class="list-title">组名称:</div>
                <el-input
                  placeholder="组名称"
                  suffix-icon="el-icon-user"
                  v-model="employees.groupName"
                >
                </el-input>
              </div>
            </el-col>
          </el-row> -->
        </div>

        <div class="list-content-btn">
          <el-button type="info" plain @click="empolyeeQuery()">查询</el-button>
        </div>
        <div class="list-contetn-add">
          <el-button type="info" plain @click="addEmployee"
            >添加员工信息</el-button
          >
        </div>
      </div>
      <tables
        :ListArr="employeeListArr"
        :tableList="employeeTableList"
        :tablePagination="enployeePagination"
        :editRow="editEmployeeRow"
        :deletes="deleteEmpolyee"
        :currentChange="empolyeeSizeChange"
        :sizeChange="empolyeeCurrentChange"
        :loading="employeeLoading"
        :isBtn="employeeBtn"
        :use="handleUse"
        :editPassword="editPassword"
      ></tables>
    </el-card>

    <el-dialog
      class="dialogBox"
      :close-on-click-modal="false"
      title="修改员工信息"
      :visible.sync="dialogVisibeeGroup"
      top="30vh"
      :show-close="false"
    >
      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="16" :md="12">
          <el-form
            id="from"
            :model="empolyeet"
            ref="ruleForm"
            label-width="100px"
            :rules="rules"
          >
            <!-- <input type="hidden" v-model="union.id" /> -->
            <el-form-item label="员工姓名:" prop="name">
              <el-input v-model="empolyeet.name"></el-input>
            </el-form-item>
            <el-form-item label="员工昵称:" prop="stuffName">
              <el-input v-model="empolyeet.stuffName"></el-input>
            </el-form-item>
            <el-form-item label="性别:">
              <el-radio-group v-model="empolyeet.gender">
                <el-radio label="1">男</el-radio>
                <el-radio label="0">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="备注:">
              <el-input v-model="empolyeet.remark" type="textarea"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row id="anniu22" type="flex">
        <el-col>
          <el-button type="success" @click="canceledits()">取消</el-button>
          <el-button type="primary" @click="submitFormUnion('ruleForm')"
            >确认</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog
      title="修改密码"
      :visible.sync="editPasswordDoalog"
      width="30%"
      >
      <el-form label-width="80px" :model="formLabelAlign">
        <el-form-item label="员工名称">
          <el-input v-model="formLabelAlign.stuffName" readonly></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input v-model="formLabelAlign.salted" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input v-model="formLabelAlign._salted" type="password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editPasswordDoalog = false">取 消</el-button>
        <el-button type="primary" @click="editPasswordSubmit">确 定</el-button>
      </span>
  </el-dialog>
  </div>
</template>
<script>
import Combination from '@/components/Combination.vue';
import addPresident from '../laborunion/addPresident';
import unionList from '../laborunion';
import tables from '../laborunion/table';
import { getLocalStorage, cleanLocalStorage } from '@/util/userLoginFn';
import { checkempolyeeName, checkempolyeeStuffName } from '@/util/proofread';
import { editStaffPassword } from '@/service/moudules/platformApi';
import {
  getGroupList,
  getEmployeeList,
  editStffList,
  deleteStffList,
  getStffList,
} from '@/service/moudules/platformApi';
import { getCookie } from '@/util/userLoginFn';
export default {
  components: {
    Combination,
    unionList,
    addPresident,
    tables,
  },
  data() {
    return {
      editPasswordDoalog:false,
      title: '员工信息',
      getGroupList,
      getLocalStorage,
      cleanLocalStorage,
      getCookie,
      checkempolyeeName,
      checkempolyeeStuffName,
      sigleuserGroup: {},
      formLabelAlign:{
        stuffName:"",
        id: "",
        // 确认密码
        _salted: "",
        // 新密码
        salted: ""
      },
      employees: {
        name: null,
        gender: null,
        stuffCode: null,
        remark: null,
        stuffName: null,
        presidentName: null,
        groupName: null,
        stuffId: null,
      },
      employeeTableList: [
        {
          name: '员工姓名',
          prop: 'name',
        },

        {
          name: '员工账号',
          prop: 'stuffCode',
        },
        {
          name: '员工昵称',
          prop: 'stuffName',
        },
        {
          name: '组名称',
          prop: 'groupName',
        },
        {
          name: '工会名称',
          prop: 'presidentName',
        },
        {
          name: '性别',
          prop: 'gender',
        },
        {
          name: '备注',
          prop: 'remark',
        },
      ],
      employeeLoading: false,
      empolyeet: {
        name: null,
        stuffName: null,
        gender: null,
        remark: null,
        stuffId: null,
      },
      enployeePagination: {
        total: 0,
        pageSize: 10,
        currentPage: 1,
      },
      employeeListArr: [],
      nOptions: [],
      dialogVisibeeGroup: false,
      groupTitles: '',
      isGroupClean: false,
      isRole: '',
      employeeBtn: 'employeeBtn',
      rules: {
        name: [
          { validator: checkempolyeeName, trigger: 'blur', required: true },
        ],
        stuffName: [
          {
            validator: checkempolyeeStuffName,
            trigger: 'blur',
            required: true,
          },
        ],
      },
    };
  },
  methods: {
    editPassword(row){
      console.log(row,this.formLabelAlign)
      this.editPasswordDoalog = true
      this.formLabelAlign.stuffName = row.stuffName
      this.formLabelAlign.id = row.stuffCode
    },
    editPasswordSubmit(){
      if(!this.formLabelAlign.salted){
        this.$message.error('请填写新密码')
        return
      }
      if(!this.formLabelAlign._salted){
        this.$message.error('请填写确认密码')
        return
      }
      if(this.formLabelAlign.salted!==this.formLabelAlign._salted){
        this.$message.error('两次填写密码不一致, 请检查')
        return
      }
      const data={
          id: this.formLabelAlign.id,
          wornSalted: '1',
          salted: this.formLabelAlign.salted,
          isLeader: 1
      }
      editStaffPassword(data).then(res=>{
        if(res.code===200){
          this.$message.success('修改密码成功')
          this.editPasswordDoalog = false
        }else{
          this.$message.success('修改失败')
        }
      });

    },
    handleUse(){
      console.log(111)
    },
    //页数修改重新加载
    empolyeeSizeChange(val) {
      this.enployeePagination.currentPage = val;
      this.empolyeeQuery();
    },
    empolyeeCurrentChange(val) {
      this.enployeePagination.pageSize = val;
      this.empolyeeQuery();
    },
    // addEmployee() {},
    canceleditGroup() {
      this.dialogVisibleeditGroup = false;
      this.empolyeeQuery('edit');
    },
    editEmployeeRow(row) {
      let { name, stuffName, gender, remark, stuffId } = row;
      this.empolyeet.name = name;
      this.empolyeet.stuffName = stuffName;
      this.empolyeet.remark = remark;
      this.empolyeet.gender = gender === '男' ? '1' : '0';
      this.empolyeet.stuffId = stuffId;
      this.dialogVisibeeGroup = true;
    },
    addEmployee() {
      this.$router.push({
        path: '/addEmployee',
      });
    },

    canceledits() {
      this.dialogVisibeeGroup = false;
      this.$refs.ruleForm.resetFields();
    },
    async submitFormUnion(formName) {
      try {
        let { name, stuffName, gender, remark, stuffId } = this.empolyeet;
        let params = {
          name,
          stuffName,
          gender,
          remark,
          stuffId,
          sessionId: this.getCookie('sessionId'),
        };

        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            let { data, code, msg } = await editStffList(params);

            if (code !== 200) {
              this.$message({
                type: 'errpr',
                message: msg,
              });
              this.dialogVisibeeGroup = false;
              return false;
            }
            this.$message({
              type: 'success',
              message: '修改员工信息成功',
            });
            this.dialogVisibeeGroup = false;
            this.empolyeeQuery();
          } else {
            return false;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },

    async deleteEmpolyee(row) {
      try {
        const { stuffId } = row;

        let params = {
          stuffId,
          sessionId: this.getCookie('sessionId'),
        };

        this.$confirm('此操作将删除该条信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            let { data, code, msg } = await deleteStffList(params);
            if (code !== 200 && msg !== 'success') {
              this.$message({
                type: 'error',
                message: msg,
              });
              return false;
            }

            this.$message({
              type: 'success',
              message: '删除信息成功',
            });
            this.empolyeeQuery();
          })
          .catch(() => {
            this.empolyeeQuery();
            return false;
          });
      } catch (error) {
        console.log(error);
      }
    },

    //表格格式化
    codeFormatter(row, column, cellValue, index) {
      return cellValue == '' ? '空' : cellValue;
    },
    //点击查询数据
    async empolyeeQuery() {
      try {
        this.employeeLoading = true;
        let {
          name,
          gender,
          stuffName,
          presidentName,
          groupName,
          stuffCode,
          remark,
        } = this.employees;

        let { pageSize, currentPage } = this.enployeePagination;

        let params = {
          name,
          gender,
          stuffName,
          sessionId: this.getCookie(['name', 'sessionId']),
          stuffCode,
          remark,
          presidentName,
          groupName,
          pageSize,
          pageNo: currentPage,
        };
        let { code, data, msg, total } = await getStffList(params);

        if (code !== 200 && data.records.length === 0) {
          this.$message({
            type: 'error',
            message: msg,
          });
          this.employeeLoading = false;
          return false;
        }
        this.employeeListArr =
          data === null
            ? []
            : data.records.map((item) => {
                item.gender = item.gender === 0 ? '女' : '男';

                return item;
              });
        this.enployeePagination.total = data === null ? 0 : data.total;
        this.$message({
          type: 'success',
          message: data === null ? '员工列表暂无数据' : '获取员工列表成功',
        });
        this.employeeLoading = false;
      } catch (error) {
        this.employeeLoading = false;
        this.employeeListArr = [];
        this.enployeePagination.total = 0;
        console.log(error);
      }
    },
  },
  created() {
    this.isRole = this.getLocalStorage('name');
    this.empolyeeQuery();
  },
};
</script>
<style lang="less" scoped>
.list-contetnt-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  .list-content-select {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    .list-title {
      width: 100px;
      font-size: 14px;
      padding: 10px;
    }
  }
  .list-content-btn {
    margin: 0 10px;
  }
  .select-width {
    width: 100%;
  }
}
.union-select {
  width: 100%;
}
.row-span {
  display: flex;
  padding: 10px;
}
</style>
